import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import history from '../history'
import { Link } from "react-router-dom"
import { useState } from 'react'
import { PUBLIC_URL } from '../helpers/domain'

const CategoryDropdown2 = ({ categories }) => {

  let publicUrl = PUBLIC_URL

  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown) 
  }
  
  const [openSubmenuIds, setOpenSubmenuIds] = useState([])
  const toggleOpenSubmenu = (id) => {
	if(openSubmenuIds.includes(id)){
	  setOpenSubmenuIds(openSubmenuIds.filter(item => item !== id))
	} else {
	  setOpenSubmenuIds([...openSubmenuIds, id])
	}
  }

//   const renderMenu = items =>
//     items.map((item, index) =>
//       <Dropdown key={index} style={{ marginLeft: 10 }}>
//         <button onMouseDown={() => onClickItem(item.slug)} style={{ width: '80%', textAlign: 'left' }} >{item.name}</button>
//         {item.all_children.length > 0 &&
//           <>
//             <Dropdown.Toggle split />
//             <Dropdown.Menu>
//               {renderMenu(item.all_children)}
//             </Dropdown.Menu>
//           </>
//         }
//       </Dropdown>
//     )

//   const onClickItem = (categorySlug) => {
//     history.push(`/productos/${categorySlug}`)
//   }

  return (
    <DropdownButton
      className='category-dropdown'
      id="dropdown-categories"
      title={<><img src={`${publicUrl}/images/icons/hamburg.png`} alt="icon" />Categorías</>}
    //   onClick={() => toggleDropdown()}
    //   onBlur={() => { if(showDropdown) toggleDropdown()}}
    //   show={showDropdown}
		autoClose={true}
    >
		<div className='category-dropdown2'>
			{categories?.length > 0 &&
				categories.map((category, index) =>
					<div className='category-dropdown__category'>
						<h4>
							<Dropdown.Item href={`/productos/${category.slug}`}>{category.name}</Dropdown.Item> 
						</h4>
						{category.all_children.length > 0 &&
							category.all_children.map((childCategory, index) =>
								<>
									<h5>
										<Dropdown.Item href={`/productos/${childCategory.slug}`}>{childCategory.name}</Dropdown.Item> 
									</h5>
									{childCategory.all_children.length > 0 &&
										childCategory.all_children.map((childSubCategory, index) =>
											<>
												<h6>
													<div className='with-submenu'>
														<Dropdown.Item href={`/productos/${childSubCategory.slug}`}>{childSubCategory.name}</Dropdown.Item>
														{
															childSubCategory.all_children.length > 0 && <span className='open-submenu' onClick={() => toggleOpenSubmenu(childSubCategory.id)}></span>
														}
													</div>
												</h6>
												{
													(childSubCategory.all_children.length > 0 && openSubmenuIds.includes(childSubCategory.id) ) &&
													<div>
														{
															childSubCategory.all_children.map((childSubSubCategory, index) =>
																<>
																	<h6 style={{paddingLeft : "20px", marginBottom : "2px"}}>
																		<Dropdown.Item href={`/productos/${childSubSubCategory.slug}`}>{childSubSubCategory.name}</Dropdown.Item> 
																	</h6>
																</>
															)
														}
													</div>
													
												}
											</>
										)
									}
								</>
							)
						}
								
					</div>
				)
			}
		</div>
    </DropdownButton>
  )
}

export default CategoryDropdown2