import React from 'react'
import {Link} from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import ProductCardActions from './product-card-actions'
import { PUBLIC_URL, REACT_APP_API_PUBLIC_FOLDER } from '../helpers/domain'

const ProductCardOffer = ({product, className}) => {
  let apiPublicFolder = REACT_APP_API_PUBLIC_FOLDER + 'storage/'
  let publicUrl = PUBLIC_URL
  let now = moment(new Date())
  let endDate = moment(product.valid_to)
  let duration = moment.duration(endDate.diff(now))
  return (
    <div className={`product-card-offer ${className}`} >
      <Row>
        <Col lg={4} className="image-col mb-lg-1">
          <Link to={`/producto/${product?.slug}`}>
            { product?.path ? 
              <img className="product-image img-fluid" src={`${apiPublicFolder}${product.path}`} alt="Imagen de producto" />
              :        
              <img className="product-image img-fluid" src={`${publicUrl}/images/nofoto.png`} alt="Imagen de producto" />       
            }
          </Link>
        </Col>
        <Col lg={7} className="item-description">
          <p className="category">{product?.category?.name}</p>
          <Link to={`/producto/${product?.slug}`}>
            <h4 className="title">{product.title.substr(0,45)}{product.title.length > 45 ? '...' : ''}</h4>
          </Link>
          <div className="old-price"><div><del>{product?.price.currency} {product?.price.amount}</del></div><div className="off">{product.discountPercent}% OFF</div></div>
          <p className="price">{product?.priceWithDiscount.currency} {product.priceWithDiscount.amount}</p>
        </Col>
        <Col md={12} lg={5} xl={4}>
        { duration.asDays() < 31 &&
          <div className="time">
            <div className="day w-border">{Math.floor(duration.asDays())}<br/> días</div>
            <div className="hour w-border">{duration.hours()}<br/> horas</div>
            <div className="minutes">{duration.minutes()}<br/> min</div>
          </div>
        }
        </Col>
        <Col md={12} lg={6} className="pl-xl-3 pl-lg-2">
          <ProductCardActions showActions={true} productId={product.id} productSlug={product.slug} buttonTheme="white"/>
        </Col>
      </Row>
    </div>
  )
}

export default ProductCardOffer