import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import ProductCardActions from './product-card-actions';
import { PUBLIC_URL, REACT_APP_API_PUBLIC_FOLDER } from '../helpers/domain';

const ProductMiniCard = ({product, className}) => {
  const [showActions, setShowActions] = useState(false)
  let apiPublicFolder = REACT_APP_API_PUBLIC_FOLDER + 'storage/'
  let publicUrl = PUBLIC_URL
  
  return (
    <div 
      className={`product-card mini ${className}`} 
      onMouseEnter={() => setShowActions(true)} 
      onMouseLeave={() => setShowActions(false)}>
        <Row>
          <Col xs={{span: 3, offset: 1}} className="pl-0 pr-0">
            <Link to={`/producto/${product?.slug}`}>
            {  product?.images.length ? 
              <img className="product-image img-fluid" src={`${apiPublicFolder}${product.images[0].path}`} alt="Imagen de producto" />
              :        
              <img className="product-image img-fluid" src={`${publicUrl}/images/nofoto.png`} alt="Imagen de producto" />       
            }
            </Link>
          </Col>
          <Col xs={8} className="info-col">
            <p className="category">{product?.category?.name}</p>
            <Link to={`/producto/${product?.slug}`}>
              <h4 className="title">{product?.title}</h4>
            </Link>
            {product?.discount_percent ? 
              <div className="price-holder"> 
                <p className="price">{product?.priceWithDiscount.currency} {product.priceWithDiscount.amount}</p>
                <div><del>{product?.price.currency} {product?.price.amount}</del></div>
              </div>
            :
              <div className="price-holder">
                <p className='price'>{product?.price.currency} {product?.price.amount}</p>
              </div>
            }
          </Col>
        </Row>
        <ProductCardActions showActions={showActions}  productId={product.id} productSlug={product.slug}/>
    </div>
  )
}

export default ProductMiniCard