let currentBaseDomain = window.location.hostname;

if (process.env.NODE_ENV !== 'production'){
    currentBaseDomain = process.env.REACT_APP_LOCAL_URL;
    console.log('currentBaseDomain: ', currentBaseDomain);
}

export const PUBLIC_URL = currentBaseDomain + process.env.REACT_APP_BASENAME;
export const REACT_APP_API_URL = currentBaseDomain + process.env.REACT_APP_API_URL;
export const REACT_APP_API_PUBLIC_FOLDER = process.env.REACT_APP_API_PUBLIC_FOLDER;
export const REACT_APP_API_VERSION = process.env.REACT_APP_API_VERSION;
export const REACT_APP_BASENAME = process.env.REACT_APP_BASENAME;